import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";

export default function Category() {
  const [open, setOpen] = useState(false);
  const [Services, setServices] = useState([]);
  const [CateLink, setCateLink] = useState("");
  const [SelectCate, setSelectCate] = useState("");
  const [Edit, setEdit] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const [ViewBanner, setViewBanner] = useState("");
  const [mobileviewbanner, setmobileviewbanner] = useState("");

  // Define columns for DataTable
  const columns = [
    {
      name: "Category",
      selector: (row) => row.category,
    },
    {
      name: "Subcategory",
      selector: (row) => row.Subcategory,
    },
    {
      name: "Services name",
      selector: (row) => row.serviceName,
    },
    {
      name: "Website Image",
      selector: (row) => (
        <img width={100} height={100} src={row?.imglink} alt="Website" />
      ),
    },
    {
      name: "Service Details Banner",
      selector: (row) => (
        <img width={100} height={100} src={row?.viewbanner} alt="Details" />
      ),
    },
    {
      name: "Service Details Mobile Banner",
      selector: (row) => (
        <img
          width={100}
          height={100}
          src={row?.mobilebanner}
          alt="View banner image"
        />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span className="me-1 edit cursor" onClick={() => handleEdit(row)}>
            Edit
          </span>
        </>
      ),
    },
  ];

  // Open modal for adding new category
  const handleAddCategory = () => {
    setEdit(null); // Reset edit state for adding new service
    setSelectCate("");
    setCateLink("");
    setViewBanner("");
    setmobileviewbanner("");
    setOpen(true);
  };

  // Save or update category data
  const handleSaveOrUpdate = async () => {
    if (!SelectCate || !CateLink || !ViewBanner || !mobileviewbanner) {
      alert("Please fill in all fields before submitting");
      return;
    }

    try {
      const url = `https://api.vijayhomeservice.com/api/userapp/updateservicelink/${
        Edit ? Edit._id : SelectCate
      }`;

      const config = {
        method: "put",
        data: {
          imglink: CateLink,
          viewbanner: ViewBanner,
          mobilebanner: mobileviewbanner,
        },
      };

      const res = await axios(url, config);

      if (res.status === 200) {
        alert("Updated successfully");
        setOpen(false); // Close modal
        getServices(); // Refresh service data
        window.location.reload(); // Reload page if necessary
      }
    } catch (error) {
      console.error("Error in handleSaveOrUpdate:", error);
    }
  };

  // Fetch services from the server
  const getServices = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomeservice.com/api/userapp/getserviced"
      );
      setServices(res.data.services);
    } catch (error) {
      console.error("Error in getServices:", error);
    }
  };

  // Populate form fields for editing
  const handleEdit = (row) => {
    setEdit(row);
    setCateLink(row.imglink);
    setViewBanner(row.viewbanner);
    setmobileviewbanner(row.mobilebanner);
    setSelectCate(row._id);
    setOpen(true); // Open modal for editing
  };

  // Filter services based on search input
  useEffect(() => {
    const filtered = Services.filter((ele) =>
      ele?.serviceName?.toLowerCase().includes(SearchValue.toLowerCase())
    );
    setFilterData(filtered);
  }, [Services, SearchValue]);

  // Load services when the component mounts
  useEffect(() => {
    getServices();
  }, []);

  return (
    <div className="row m-auto p-2">
      <div className="row text-center">
        <div className="col-md-2">
          <Form.Control
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Services"
          />
        </div>
      </div>
      {/* <Button variant="primary" onClick={handleAddCategory}>
        Add Category
      </Button> */}
      <DataTable
        className="mt-2"
        columns={columns}
        data={filterData}
        pagination={true}
      />

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{Edit ? "Edit Service" : "Add Service"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Service Name</Form.Label>
            {Edit ? (
              <Form.Control value={Edit.serviceName} readOnly />
            ) : (
              <Form.Select
                value={SelectCate}
                onChange={(e) => setSelectCate(e.target.value)}
              >
                <option value="">Select Service</option>
                {Services.map((Ele) => (
                  <option key={Ele._id} value={Ele._id}>
                    {Ele.serviceName}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Service Link</Form.Label>
            <Form.Control
              type="text"
              value={CateLink}
              onChange={(e) => setCateLink(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>View Banner</Form.Label>
            <Form.Control
              type="text"
              value={ViewBanner}
              onChange={(e) => setViewBanner(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mobile View Banner</Form.Label>
            <Form.Control
              type="text"
              value={mobileviewbanner}
              onChange={(e) => setmobileviewbanner(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveOrUpdate}>
            {Edit ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
