import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";

export default function Pmbanner() {
  const [open, setOpen] = useState(false);
  const [bannerData, setbannerData] = useState([]);
  const [CateLink, setCateLink] = useState("");
  const [mobilebanner, setmobilebanner] = useState("");
  const [SelectCate, setSelectCate] = useState("");
  const [Edit, setEdit] = useState(null);

  const columns = [
    {
      name: "Website Image",
      selector: (row) => <img width={100} height={100} src={row?.webbanner} />,
    },
    {
      name: "Mobile Image",
      selector: (row) => (
        <img width={100} height={100} src={row?.mobilebanner} />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span className="me-1 edit cursor" onClick={() => handleEdit(row)}>
            Edit
          </span>
          <span className="m-auto me-1 text-bold">|</span>
          <span className="delete cursor" onClick={() => handleDelete(row)}>
            Delete
          </span>
        </>
      ),
    },
  ];

  console.log("bannerData", bannerData);

  const handleSaveOrUpdate = async () => {
    try {
      const url = `https://api.vijayhomeservice.com/api/pmbanner/updatepmbanner/${
        Edit ? Edit._id : SelectCate
      }`;
      const config = {
        url,
        method: "put",
        data: {
          webbanner: CateLink,
          mobilebanner: mobilebanner,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("Updated successfully");
        setOpen(false);
        getbanner();
        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  useEffect(() => {
    getbanner();
    if (Edit) {
      setCateLink(Edit.webbanner);
      setmobilebanner(Edit.mobilebanner);
      setSelectCate(Edit._id);
    }
  }, [Edit]);

  const getbanner = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomeservice.com/api/pmbanner/getallpmbanner"
      );
      setbannerData(res.data.banner);
    } catch (error) {
      console.log("Error in getbanner:", error);
    }
  };

  const handleEdit = (row) => {
    setEdit(row);
    setOpen(true);
  };

  const handleDelete = async (row) => {
    try {
      const res = await axios.post(
        `https://api.vijayhomeservice.com/api/pmbanner/postdeletpmbanner/${row._id}`
      );
      if (res.status === 200) {
        alert("Deleted Succesfully");
        getbanner();
        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in getbanner:", error);
    }
  };

  const handleAddBanner = async () => {
    try {
      const config = {
        url: "https://api.vijayhomeservice.com/api/pmbanner/addpmbanner",
        headers: { "Content-Type": "application/json" },
        method: "post",
        data: {
          webbanner: CateLink,
          mobilebanner: mobilebanner,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("added successfully");
        setOpen(false);
        getbanner();
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  //   const handleAddBanner = async () => {
  //     try {
  //       const url = `https://api.vijayhomeservice.com/api/pmbanner/addpmbanner`;

  //       const config = {
  //         url,
  //         method: "post",
  //         data: {
  //           webbanner: CateLink,
  //           mobilebanner: mobilebanner,
  //         },
  //       };

  //       const res = await axios(config);

  //       if (res.status === 200) {
  //         alert("Banner added successfully");
  //         getbanner();
  //         setOpen(false);
  //       }
  //     } catch (error) {
  //       console.log("Error in handleSaveOrUpdate:", error);
  //     }
  //   };

  return (
    <div className="row m-auto p-2">
      <div className="row text-center">
        <div className="col-md-3 d-flex m-auto">
          <span className="m-auto text-bold">
            Packers Movers banner Management{" "}
          </span>
          <MdOutlineLibraryAdd
            onClick={() => setOpen(true)}
            className="m-auto"
          />
        </div>
      </div>
      <DataTable columns={columns} data={bannerData} pagination={true} />

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Edit ? "Edit Webbanner" : "Add Web banner"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Web banner Link</Form.Label>
            <Form.Control
              onChange={(e) => setCateLink(e.target.value)}
              type="text"
              value={CateLink}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mobile banner Link</Form.Label>
            <Form.Control
              onChange={(e) => setmobilebanner(e.target.value)}
              type="text"
              value={mobilebanner}
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="col-md-2 me-auto"
            variant="secondary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          {Edit ? (
            <Button
              className="col-md-2"
              variant="primary"
              onClick={handleSaveOrUpdate}
            >
              update
            </Button>
          ) : (
            <Button
              className="col-md-2"
              variant="primary"
              onClick={handleAddBanner}
            >
              save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
