import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Blog() {
  const [open, setOpen] = useState(false);
  let initialdata = {
    title: "",
    imagelink: "",
    desc: "",
    comment: "",
  };

  const [Testimonialdata, setTestimonialdata] = useState(initialdata);
  const [Testimonial, setTestimonial] = useState([]);
  const [SelectCate, setSelectCate] = useState("");
  const [Edit, setEdit] = useState(null);

  const handleEditorChange = (event, editor) => {
    const data1 = editor.getData();
    setTestimonialdata((prevData) => ({
      ...prevData,
      comment: data1,
    }));
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Description",
      selector: (row) => row.desc,
    },

    {
      name: "Images",
      selector: (row) => row.imagelink,
    },

    {
      name: "Comment",
      selector: (row) => row.comment,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span className="me-1 edit cursor " onClick={() => handleEdit(row)}>
            Edit
          </span>{" "}
          <span className="m-auto me-1 text-bold">|</span>
          <span className="delete cursor" onClick={() => handleDelete(row)}>
            Delete
          </span>
        </>
      ),
    },
  ];

  const handleAddCategory = () => {
    setEdit(null);
    setSelectCate();

    setOpen(true);
  };

  const handleSaveOrUpdate = async () => {
    try {
      const url = `https://api.vijayhomeservice.com/api/blogs/updatedblogs/${
        Edit ? Edit._id : SelectCate
      }`;
      const config = {
        url,
        method: "put",
        data: {
          title: Testimonialdata.title,
          imagelink: Testimonialdata.imagelink,
          desc: Testimonialdata.desc,
          comment: Testimonialdata.comment,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("Updated successfully");
        setOpen(false);
        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  const handleAddTestimonial = async () => {
    try {
      const config = {
        url: "https://api.vijayhomeservice.com/api/blogs/addblogs",
        headers: { "Content-Type": "application/json" },
        method: "post",
        data: {
          title: Testimonialdata.title,
          imagelink: Testimonialdata.imagelink,
          desc: Testimonialdata.desc,
          comment: Testimonialdata.comment,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("added successfully");
        setOpen(false);

        getWhyChooose();
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  useEffect(() => {
    if (Edit) {
      setTestimonialdata({
        title: Edit.title || Testimonialdata.title,
        imagelink: Edit.imagelink || Testimonialdata.imagelink,
        desc: Edit.desc || Testimonialdata.desc,
        comment: Testimonialdata.comment,
      });
    }
    getWhyChooose();
  }, [Edit]);

  const getWhyChooose = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomeservice.com/api/blogs/getallblogs"
      );
      setTestimonial(res.data.data);
    } catch (error) {
      console.log("Error in getcategory:", error);
    }
  };

  const handleDelete = async (row) => {
    try {
      const res = await axios.post(
        `https://api.vijayhomeservice.com/api/blogs/deleteblogs/${row._id}`
      );
      if (res.status === 200) {
        alert("Deleted Succesfully");
        getWhyChooose();
        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in getcategory:", error);
    }
  };

  const handleEdit = (row) => {
    setEdit(row);
    setOpen(true);
  };
  const handleChange = (e) => {
    let { value, name } = e.target;
    setTestimonialdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // console.log(Testimonialdata, "Testimonialdata");

  return (
    <div className="row m-auto p-2">
      <div className="row text-center">
        <div className="col-md-3 d-flex m-auto">
          <span className="m-auto text-bold"> Meta Details </span>
          <MdOutlineLibraryAdd
            onClick={handleAddCategory}
            className="m-auto cursor"
          />
        </div>
      </div>
      <DataTable columns={columns} data={Testimonial} pagination={true} />

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Edit ? "Edit Meta Details" : "Add Meta Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Title"
              value={Testimonialdata.title}
              name="title"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              name="imagelink"
              placeholder="imagelink"
              value={Testimonialdata.imagelink}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Description"
              value={Testimonialdata.desc}
              name="desc"
            />
          </Form.Group>

          <Form.Label>Comment</Form.Label>

          <CKEditor
            editor={ClassicEditor}
            data={Testimonialdata.comment}
            onChange={handleEditorChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="col-md-2 me-auto"
            variant="secondary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          {Edit ? (
            <Button
              className="col-md-2 "
              variant="primary"
              onClick={handleSaveOrUpdate}
            >
              Update
            </Button>
          ) : (
            <Button
              className="col-md-2 "
              variant="primary"
              onClick={handleAddTestimonial}
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
